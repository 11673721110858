import * as React from 'react';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { graphql } from 'gatsby';
import Carousel from '../components/Carousel';
import HoverEffect from '../components/HoverEffect';

export default function IndexPage({ data, location }) {
  const { allMarkdownRemark } = data;
  const { nodes } = allMarkdownRemark;
  const filteredNodes = nodes.filter((node) =>
    node.frontmatter.language.includes('en')
  );

  return (
    <Layout location={location} lang="en">
      <Seo title="Home" />
      <Carousel>
        {nodes.map((node) => (
          <div key={node.id}>
            <GatsbyImage
              style={{ height: '92vh' }}
              image={
                node.frontmatter.featuredImage.childImageSharp.gatsbyImageData
              }
              alt={node.frontmatter.title}
            />
          </div>
        ))}
      </Carousel>
      <section className="py-20 md:py-30">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="flex flex-col-reverse flex-col items-center justify-between lg:flex-row">
            <div className="relative lg:w-1/2">
              <StaticImage
                className="object-cover w-full shadow-lg"
                src="../images/livingroom-cam-1.jpg"
                placeholder="blurred"
                alt="Living Room"
              />
            </div>
            <div className="lg:max-w-lg lg:mb-0 lg:pl-10 lg:w-1/2 mb-10">
              <div className="max-w-xl mb-6">
                <h2 className="max-w-lg mb-6 text-4xl tracking-tight text-gray-900 dark:text-white sm:text-5xl sm:leading-none">
                  Atelier Motif
                </h2>
                <ul className="-ml-1 space-y-2 text-base text-gray-700 dark:text-white md:text-lg">
                  <li className="flex items-start">
                    <span className="mr-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </span>
                    Interior design
                  </li>
                  <li className="flex items-start">
                    <span className="mr-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </span>
                    Architecture
                  </li>
                  <li className="flex items-start">
                    <span className="mr-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </span>
                    Art
                  </li>
                  <li className="flex items-start">
                    <span className="mr-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </span>
                    Furniture design and fabrication
                  </li>
                  <li className="flex items-start">
                    <span className="mr-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </span>
                    Project realization
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <h2 className="pt-14 text-center mb-6 text-4xl tracking-tight text-gray-900 dark:text-white sm:text-5xl sm:leading-none">
          Some of our projects
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 mb-10 py-10">
          {filteredNodes.slice(0, 3).map((node) => (
            <HoverEffect
              key={node.id}
              slug={node.frontmatter.slug}
              image={
                node.frontmatter.featuredImage.childImageSharp.gatsbyImageData
              }
              title={node.frontmatter.title}
            />
          ))}
        </div>
      </section>
    </Layout>
  );
}

export const indexQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { html: { ne: "" }, frontmatter: { language: { eq: "en" } } }
      sort: { fields: frontmatter___featuredImage___birthTime, order: DESC }
    ) {
      nodes {
        id
        frontmatter {
          title
          language
          slug
          location
          size
          timeframe
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`;
